import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse from "html-react-parser"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LearningBlock, BackgroundColourBlock } from "../styledComponents/overView"
import { 
  ProgramSection, 
  ProgramOverview,
  ProgramLectureHeader,
  ProgramLectureList,
  ProgramLectureListItem } from '../styledComponents/program'
import { StyledButton, StyledCloseModalButton } from "../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import {StyledModal } from "../styledComponents/modal"
import HeroBanner from "../components/HeroBanner"
import Icon from "../components/icon"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"  
  
  function FancyModalButton(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
  
    return (
      <>   
        <StyledButton onClick={toggleModal}>Learn more</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className="index-modal"
        >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
          {parse(data.data.programContent)}
        </StyledModal>
      </>
    );
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
  `;


const Program = ({ data }) => {
  return (
    <>
    <Layout>
      <SEO title={data.wpPage.title} />

      <HeroBanner image={data.wpPage.heroSection.kaarmibeingHeroBackground.localFile}>
          <HeroText>
            <HeroHeader>
                {data.wpPage.heroSection.kaarmibeingHeroHeading}
            </HeroHeader>
            <HeroDescription>{data.wpPage.heroSection.kaarmibeingHeroLedeText}</HeroDescription>
            <AnchorLink to="/program#overview">Find out more</AnchorLink>
          </HeroText>
      </HeroBanner>

      <ProgramSection id="overview">
        <ProgramOverview>
          <h2>KaarmiBeing LIVE online Level 1 </h2>
          <p>The KaarmiBeing workshops provide beginners and advanced level learning opportunities about how to improve wellbeing and overall quality of life.</p>
          <p>In this highly unique live workshop you will learn how to apply fascinating ancient teachings in a very practical way which can be implemented in daily life.</p>
          <p>We want you to be able to start using the techniques in your life straight away so we teach this as a continuous flow of 7 weekly sessions. </p>
          <p>This will enable us to give you a space for growth and continuity to create lasting positive changes in your life. Each week you will deepen your ability to apply and observe the KaarmiBeing techniques in the main key areas of your life.</p>
          <ul>
            <li>Learn the essential KaarmiBeing knowledge in the 7 sessions</li>
            <li>Discover how to embody these techniques and the knowledge in a highly practical way, in your daily life.</li>
            <li>These sessions are taught in an interactive format. You will have the opportunity to ask questions, and learn how to implement the knowledge in your life.</li>
          </ul>
        </ProgramOverview>
      </ProgramSection>

      <BackgroundColourBlock number="1">
          <LearningBlock>
              <figure>
              <img src="/static/165b0273a14d0dec55138a222df07c82/dealing-with-stress-wellness.svg" alt="" />
              </figure>
              <div className="overviewWrapper">
                  <h2>You will also learn: </h2>
                  <ul>
                    <li>Practical exercises and techniques</li>
                    <li>Specific meditation postures</li>
                    <li>Specific breathing exercises</li>
                    <li>How to unite your mind, speech and body for an optimum focused state of mind</li>
                    <li>How to calm the mind and reset the nervous system</li>
                    <li>We will host live interactive discussions with in depth instructions to enable each person to gain a true understanding of this knowledge</li>
                    <li>You will learn how to integrate all of the above in your daily life</li>
                  </ul>
              </div>               
          </LearningBlock>
        </BackgroundColourBlock>

      <ProgramSection>

        <ProgramLectureHeader>We will address the following main key areas of life:</ProgramLectureHeader>
        <ProgramLectureList>
          
          {data.wpPage.OurProgram.programData.map(
            (data, index) => {
              const lectureImage = {
                imageData: data.svg.localFile.publicURL,
              }

            return (
              <ProgramLectureListItem>
                <img src={lectureImage.imageData} alt="" />
                <h2>{data.programHeader}</h2>
                <p>{data.programLede}</p>
                <ModalProvider backgroundComponent={FadingBackground}>
                    <FancyModalButton data={data}/>
                </ModalProvider>
              </ProgramLectureListItem>
            )}
          )}
        </ProgramLectureList>
        <ProgramOverview>
        <p>In order to maximise your experience we have integrated the practical exercises above into one program. The weekly gap between each session gives you the perfect opportunity to practice and return each week with your questions and experiences. </p>
        <p>We know that people are very busy and that time is often limited. Therefore we have created a program structure that supports maximum growth in a practical and realistic timeframe for you. </p>
        <p>The KaarmiBeing techniques are easy to learn and you will be able to use them in your daily life with ease.</p>
        </ProgramOverview>
      </ProgramSection>
    </Layout>
    </>
  )
}

export default Program

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "program" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
                childImageSharp {
                    sizes {
                        src
                    }
                }
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
